import * as React from 'react'

const Skills = ({skillList}) => {
    return (
        <div className="skills">
            <h2>A list of my skills</h2>
            <ul className ="skill-list">
            {skillList.map(item => {
                return <li>{item}</li>;
            })}
            </ul>
        </div>
    )
  }
  
  export default Skills