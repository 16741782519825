import * as React from 'react'
import Layout from '../components/layout'
import Skills from '../components/blocks/skills'
let aboutFace = require('../images/about-me.jpg')

let mySkills = [
"Drupal 7, 8, & 9",
"HTML 5",
"CSS3, SASS, & SCSS",
"Bootstrap",
"JavaScript, jQuery, & React",
"Gatsby",
"PHP & Twig",
"Lando & DDEV",
"Git & Version Control Fundamentals",
"GitHub, Bitbucket, & Pantheon",
"WCAG 2.1 & Section 508 Compliance",
"Visual Studio Code",
"Adobe Photoshop, Illustrator, Premier Pro, After Effects, & Audition",
"Windows, Mac OS, & Linux",
"Microsoft Office Suite",
"Google Workspace",
"General Video & Audio Production"
];

const AboutPage = () => {
  return (
    
    <Layout pageTitle="About Me">
      <h1>A little about myself</h1>
      <img className="selfie" src={aboutFace.default} alt="A portrait of Daniel Richtmyre. A smiling white man wearing a burgundy beanie on his head, with navy, thick-rimmed glasses. He is wearing a slightly open North Face jacket with yellow, purple, and red fabric. In the background is an out-of-focus river." width="800" />
      <p>I've been a computer and internet aficionado for pretty much my entire life, or at least since my parents brought home an IBM PC with Windows 95 and an AOL trial CD. My first real foray into web design was as a middle-schooler obsessed with Neopets; I started learning how to use HTML to transform my pet’s personal pages (for the uninformed: Neopets is/was a web game where you adopted up to four pets, and each pet had their own personal web page you could customize) into Web 1.0 masterpieces, with single digit frame-count GIFs, MIDI renditions of my favorite Linkin Park songs, and repeating backgrounds of electric blue flames with red text laid on top. Eventually I yearned for more and signed up for a GeoCities account, and began working on a rudimentary fantasy, choose-your-own-adventure game, complete with characters, weapons and armor, imaginary cities, and a grand adventure to experience. You would click a series of hyperlink after hyperlink to navigate the world I created rather than “playing” it in a traditional, video-game sense, but regardless, I spent hours and weeks crafting my little HTML fantasy realm. Like every millennial teenager though, I eventually moved on to MySpace, where I was constantly researching new ways to make my profile page more unique (and perhaps gaudy) than everyone else’s. </p>
      <p>As read-only web content began to fade and social media began to take over the internet, my interest in the creation of web content waned for a bit until I was taking classes required for my Media Arts & Design degree at James Madison University. One class was an intro course to creating digital media. It covered basic skills in things like Adobe Photoshop, Illustrator, audio and video production, and writing some simple HTML5 and CSS3. Even though we only really got as far as creating a basic “Hello World!”-style page with an image and some basic facts about ourselves, that experience reignited a desire to create websites. Although I was fairly locked in to the degree program to study digital video production at that point, I still took the more advanced web design course offered the following semester and began creating more advanced projects. For my final project, I created a new website for our school’s radio station, where I was a DJ with a weekly radio show, and a few weeks later I has the opportunity to present my work to the heads of the station in a bid for the role of the station’s webmaster, a position that had been vacant for a couple of years (hence the need for a new website). I was given the position, and spent my senior year of undergrad building up a new website for the station, its staff, the radio shows it hosted, and local events - all from scratch.</p>
      <p>After I graduated, my experience as the radio station's webmaster helped me to land a web internship at Americans for the Arts. There, I helped launch their new Drupal 7 website in late 2013, cleaning up a mountain of migrated content from their old website. At the conclusion of my internship, their Operations Associate position opened, and I really enjoyed my time at the organization, so I decided to apply for it. While an operations role was a bit of a departure from web development, I spent a little under two years ensuring the office space functioned smoothly before a position opened up on the web team that I had interned with previously. After a little bit of rigmarole, I accepted the role of Website Associate, and I have been learning and growing my skills as the organization’s web specialist ever since. These days I’ve graduated to the title of Director of Web Development and Strategies, leading the organization’s web presence and using my front end skills to help create engaging experiences to showcase the value of the arts in America, and help others make the case for the power of the arts in their own local communities as well.</p>
      <hr />
      <Skills skillList={mySkills} />
    </Layout>
  )
}


export default AboutPage
